<template>
    <div>
        <div class="header-top">
            <div class="top-content">
                <div class="top-right">
                    <router-link v-if="!logined" to="/login" class="ns-text-color">您好，请登录</router-link>
                    <div v-if="logined" class="member-info">
                        <router-link to="/member/index">{{ member.nickname || member.username }}</router-link>
                        <span @click="logout">退出</span>
                    </div>
                    <router-link v-if="!logined" to="/register">免费注册</router-link>
                    <router-link to="/member/order_list">我的订单</router-link>
					<router-link to="/member/footprint">我的足迹</router-link>
					<router-link to="/member/collection">我的关注</router-link>
					
                    <router-link to="/cms/help">帮助中心</router-link>
					<router-link to="/cms/notice">公告</router-link>
                    <el-dropdown v-if="qrcode">
                        <span class="el-dropdown-link">
                            小程序二维码
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <div class="mobile-qrcode">
									<el-image  :src="$img(qrcode)" >
										<div slot="error" class="image-slot">
											<img :src="require('@/assets/images/wxewm.png')" />
										</div>
									</el-image>
								</div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <div>
                        <el-button type="primary" plain style="padding:5px 8px"
                                   size="small"  @click="shoucang('小药夫','.url','https://www.shenglitaiyy.com/')">保存到桌面</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import { getToken } from "@/utils/auth"
	import {
		copyRight,
		shopServiceLists,
		friendlyLink,
	    weQrcode,
	    websiteInfo
	} from "@/api/website"
    export default {
        props: {},
        data() {
            return {
				qrcode:""
			}
        },
        created() {
            this.$store.dispatch("site/qrCodes")
            this.$store.dispatch("member/member_detail")
            this.$store.dispatch("site/defaultFiles")
            this.$store.dispatch("site/addons");
			this.getqrcodeimg();
        },
        mounted() {},
        watch: {},
        methods: {
            shoucang(a,b,c){
                let d = ''+a+b;
                let e = '[InternetShortcut]'+'\n'+'URL='+c;
                this.saveToDesktop(d,e);
            },
            saveToDesktop(fileName, data) {
                let explorer = navigator.userAgent.toLowerCase()
                if (explorer.indexOf("trident") > 0) {//IE 5+
                    let wss = new ActiveXObject("WScript.Shell")
                    let desktopUrl = wss.SpecialFolders("Desktop")
                    let fso = new ActiveXObject("Scripting.FileSystemObject")
                    let tf = fso.CreateTextFile(desktopUrl + "\\" + fileName, true)
                    tf.Write(data)
                    tf.Close()
                } else {//fireFox chrome
                    let urlObj = window.URL || window.webkitURL || window
                    let blob = new Blob([data])
                    let link = document.createElementNS("http://www.w3.org/1999/xhtml", "a")
                    link.href = urlObj.createObjectURL(blob)
                    link.download = fileName
                    saveClick(link)
                }

                function saveClick(obj) {
                    let e = document.createEvent("MouseEvents")
                    e.initMouseEvent(
                        "click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null
                    )
                    obj.dispatchEvent(e)
                }
            },
            logout() {
                this.$store.dispatch("member/logout")
            },
			getqrcodeimg(){
			    weQrcode({}).then(res => {
			            if (res.code == 0 && res.data) {
			                this.qrcode = res.data
			            }
			        })
			        .catch(err => {
			            this.$message.error(err.message)
			        })
			},
			adRightImageError(index) {
				this.adRightList[index].adv_image = this.defaultGoodsImage
			},
        },
        components: {},
        computed: {
            ...mapGetters(["wapQrcode", "member", "addonIsExit"]),
            qrcode: function() {
                return this.wapQrcode === "" ? "" : this.wapQrcode.path.h5.img
            },
            logined: function() {
                return this.member !== undefined && this.member !== "" && this.member !== {}
            }
        }
    }
</script>

<style scoped lang="scss">
    .header-top {
        width: 100%;
        height: 31px;
        font-size: 12px;
        background-color: #f5f5f5;

        .el-dropdown {
            font-size: $ns-font-size-sm;
        }
        .top-content {
            width: $width;
            height: 100%;
            margin: 0 auto;
            .top-right {
                height: 100%;
                float: right;
                font-size: $ns-font-size-sm;
                color: $ns-text-color-black;
                a {
                    float: left;
                    line-height: 31px;
                    padding: 0 10px;
                    &:hover {
                        color: $base-color;
                    }
                }
                div {
                    float: left;
                    height: 100%;
                    margin-left: 10px;
                    cursor: pointer;
                    line-height: 31px;
                    padding: 0 5px;

                    &.member-info {
                        margin-left: 0;
                        span:first-child {
                            margin-right: 10px;
                        }
                    }
                    span:hover {
                        color: $base-color;
                    }
                    &.el-dropdown:hover {
                        background-color: #fff;
                    }
                }
            }
        }
    }
    .mobile-qrcode {
        padding: 10px 0;
    }
	.router-link-active{
		color:$base-color;
	}
</style>
