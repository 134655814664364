<template>
    <div class="footer-bottom">
        <div class="site-info">
            <p v-if="siteInfo.web_phone"><i class="iconfont icondianhua"></i>{{ siteInfo.web_phone }}</p>
            <p v-if="siteInfo.web_email">
                <i class="iconfont iconyouxiang"></i>
                <el-link :href="`mailto:${siteInfo.web_email}`">{{ siteInfo.web_email }}</el-link>
            </p>
        </div>
        <p>
            {{ copyRight.copyright_desc }}
            <a v-if="copyRight.icp" class="footer-link" href="https://beian.miit.gov.cn" target="_blank">备案号：{{ copyRight.icp }}</a>
        </p>
        <div class="flex_basic">
            <a  class="a_link" href="https://image.shenglitaiyy.com/upload/certificate/营业执照.jpg" target="_blank">营业执照</a>
            <a  class="a_link" href="https://image.shenglitaiyy.com/upload/certificate/第二类医疗器械备案凭证.jpg" target="_blank">第二类医疗器械备案凭证</a>
            <a  class="a_link" href="https://image.shenglitaiyy.com/upload/certificate/食品经营许可证.jpg" target="_blank">食品经营许可证</a>
            <a  class="a_link" href="https://image.shenglitaiyy.com/upload/certificate/药品经营许可证.jpg" target="_blank">药品经营许可证</a>
            <a  class="a_link" href="https://image.shenglitaiyy.com/upload/certificate/医疗器械经营许可证.jpg" target="_blank">医疗器械经营许可证</a>
            <a  class="a_link" href="https://image.shenglitaiyy.com/upload/certificate/互联网药品信息服务资格证书.jpg" target="_blank">互联网药品信息服务资格证书</a>
            <a  class="a_link" href="https://image.shenglitaiyy.com/upload/certificate/电信增值业务经营许可证.jpg" target="_blank">电信增值业务经营许可证</a>
            <a  class="a_link" href="https://image.shenglitaiyy.com/upload/1/common/images/20231115/20231115052007170004000745746.png" target="_blank">医疗器械网络销售 备案证明</a>
        </div>
        <div class="flex_basic">
            本网站未发布疫苗、血液制品、麻醉药品、精神药品、医疗用毒性性药品、放射性药品、药品类易制毒化学品等国家实行特殊管理的药品信息
        </div>
        <p>
            <a v-if="copyRight.gov_record" class="footer-link" :href="copyRight.gov_url" target="_blank">
                <img src="@/assets/images/gov_record.png" alt="公安备案" />
                <span>{{ copyRight.gov_record }}</span>
            </a>
        </p>
    </div>
</template>

<script>
    import { copyRight } from "@/api/website"
    import { mapGetters } from "vuex"
    export default {
        props: {},
        data() {
            return {}
        },
        computed: {},
        created() {
            this.$store.dispatch("site/copyRight")
        },
        mounted() {},
        watch: {},
        methods: {},
        computed: {
            ...mapGetters(["copyRight", "siteInfo"])
        }
    }
</script>

<style scoped lang="scss">
    .flex_basic{
        display: flex;align-items: center;justify-content: center;
    }
    .a_link{
        color:#9D9D9D;
        margin:0 10px
    }
    .footer-bottom {
        width: 100%;
        height: 85px;
        margin: 0 auto;
        padding: 20px 0;
        p {
            margin: 0;
            width: 100%;
            box-sizing: border-box;
            text-align: center;
            img {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
            .footer-link, .el-link.el-link--default {
                color: #9d9d9d;
            }
			.footer-link:hover, .el-link.el-link--default:hover {
				color: $base-color;
			}
        }
        .site-info {
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                width: auto;
                margin: 0 10px;

                i {
                    vertical-align: bottom;
                    margin-right: 5px;
                }
            }
        }
    }
</style>
