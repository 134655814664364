export default {
	// api请求地址
	baseUrl: 'https://yddv4.shenglitaiyy.com/',
	// baseUrl: 'http://localhost',
	// 图片域名
	imgDomain: 'https://yddv4.shenglitaiyy.com/', //https://yddv4.shenglitaiyy.com/
	// 前端域名
	webDomain: '',
	// 腾讯地图key
	mpKey: '',
	// 客服
	webSocket: '',
	//本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
	pingInterval: 1500
}